import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="filters"
export default class extends Controller {
  static targets = ["filters", "listingsTable"];

  connect() {
    // Listen for popstate events (back/forward button presses)
    window.addEventListener("popstate", this.handlePopState.bind(this));
  }

  disconnect() {
    // Clean up the event listener when the controller is disconnected
    window.removeEventListener("popstate", this.handlePopState.bind(this));
  }

  updateUrl() {
    const url = new URL(window.location);

    // Clear all search params
    url.search = '';

    this.filtersTargets.forEach((checkbox) => {
      if (checkbox.checked) {
        url.searchParams.append(checkbox.dataset.param + "[]", checkbox.name);
      }
    });

    this.listingsTableTarget.src = url.toString();
    history.pushState({}, '', url.toString());
  }

  handlePopState() {
    // Need to refresh whole page so checkbox filters are correct.
    window.location.reload();
  }
}
